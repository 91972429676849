<template>
  <div class="template">
    <div class="header">
      <div class="flex justify-end">
        <img src="@/assets/logo.svg" style="width:80px" alt="Gries Logo" />
      </div>
    </div>
    <div>
      <div>
        <tr>
          <td>Notiz</td>
          <td style="text-align:right">Infos</td>
        </tr>
      </div>

      <div>
        <tr>
          <td contenteditable style="min-width: 250px;">
            Test
          </td>
          <td style="min-width: 250px; text-align:right">
            <div>
              <b>Datum: </b>
              <span contenteditable @keydown="preventLineBreaks">{{
                today
              }}</span>

              <input type="date" :value="today" />
            </div>
            <div>
              <b>Order number:</b>
              <span contenteditable @keydown="preventLineBreaks"
                >W210984204</span
              >
            </div>
            <div>
              <b>Order date:</b>
              <span contenteditable @keydown="preventLineBreaks">{{
                today
              }}</span>
            </div>
            <div>
              <b>Your client number:</b>
              <span contenteditable @keydown="preventLineBreaks">120948</span>
            </div>
          </td>
        </tr>
      </div>
    </div>
    <h2>Your order</h2>
    <div style="margin-bottom:20px">Thank you for your order.</div>
    <div>
      <b>Payment method:</b>
      <span contenteditable @keydown="preventLineBreaks">Bank transfer</span>
    </div>
    <div>
      <b>Payment term:</b>
      <span contenteditable @keydown="preventLineBreaks">{{ next_month }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invoice_number: String
  },
  data() {
    return {
      is_shipping_identical: true,
      billing_address:
        "<b>M. John Doe</b><br>98 Ooezfjefoi Laozdij<br>09823 Ljeiznr<br>FOPKSOFOF<br>AC310592815039"
    };
  },
  mounted() {
    // initialize contenteditable fields manually
    this.$refs.billing_address.innerHTML = this.billing_address;
    this.$refs.tax_percent.innerHTML = this.tax_percent;
    this.$refs.shipping.innerHTML = this.shipping + ".00";
  },
  computed: {
    today() {
      const date = new Date();
      return date.toLocaleDateString();
    },
    next_month() {
      const date = new Date();
      date.setDate(date.getDate() + 30);
      return date
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, ".");
    },
    sub_total() {
      return this.items.reduce(
        (acc, item) => (acc += item.qty * item.price),
        0
      );
    },
    total() {
      return this.sub_total * (1 + this.tax_percent / 100) + this.shipping;
    }
  },
  methods: {
    preventLineBreaks(e) {
      if (e.which === 13) e.preventDefault();
    }
  }
};
</script>

<style scoped lang="scss">
table {
  width: 100%;
  border-spacing: 0;
}
table td {
  position: relative;
  padding: 6px;
  vertical-align: top;
}
table.order-table td {
  vertical-align: middle;
}
table thead td {
  font-weight: bold;
}
table tbody td {
  border-top: solid 1px #ccc;
}
input[type="number"] {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: rgba(200, 250, 230, 0.2);
  padding: 6px;
}
*[contenteditable="true"] {
  background: rgba(200, 250, 230, 0.2);
  transition: padding 0.05s linear;
}
span[contenteditable="true"]:focus {
  padding: 0 4px;
}
@media print {
  *[contenteditable="true"],
  input {
    background: none;
  }
  .hide-in-print {
    display: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
    background: none;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.left-btn {
  position: absolute;
  left: -40px;
  top: 2px;
}
.left-btn:hover {
  opacity: 0.6;
}
</style>
