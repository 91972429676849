import { render, staticRenderFns } from "./TextDocument.vue?vue&type=template&id=8080760a&scoped=true&"
import script from "./TextDocument.vue?vue&type=script&lang=js&"
export * from "./TextDocument.vue?vue&type=script&lang=js&"
import style0 from "./TextDocument.vue?vue&type=style&index=0&lang=css&"
import style1 from "./TextDocument.vue?vue&type=style&index=1&id=8080760a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8080760a",
  null
  
)

export default component.exports