<template>
  <div>
    <h2>Notizbuch</h2>
    <text-document />
  </div>
</template>

<script>
import TextDocument from "../components/TextDocument.vue";

export default {
  name: "TextEditor",
  components: {
    TextDocument
  }
};
</script>

<style scoped></style>
